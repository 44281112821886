<template>
    <div class="ordershangmeng">
        <!--        第一个卡片-->
        <div class="ordershangmeng_card">
            <div class="ordershangmeng_card_header">
                <div class="p1">{{item.postName}}</div>
                <div class="p2">
                    <img src="assets/order_card_location.png"/>
                    <div class="location">{{item.address}}</div>
                </div>
            </div>

            <div class="ordershangmeng_card_content">
                <div class="item">
                    <div class="p1">服务日期</div>
                    <div class="p2">{{item.time}}</div>
                    <div class="p3">{{item.categoryName}}</div>
                </div>
                <div class="item">
                    <img v-for="(imgItem,index) in item.pictures" :key="'img'+index" :src="imgItem" @click="showImg(imgItem,item.pictures)" />
<!--                    <img src="assets/demo_i1.png"/>-->
<!--                    <img src="assets/demo_i2.png"/>-->
<!--                    <img src="assets/demo_i3.png"/>-->
                </div>
                <div class="item remark">
                    <div>投标说明&要求</div>
                    <div class="p4">
                        <div v-html="item.info"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--        第二个卡片-->
        <!--        提交支付凭证-->
        <div class="ordershangmeng_card title bottom" v-if="state=='去支付'">
            <div class="ordershangmeng_card_info">提交支付凭证</div>
            <div class="ordershangmeng_card_imgs">
                <div style="position:relative"  v-for="(imgFile,index) in payImageViews" :key="'sign'+index" >
                    <div class="close">
                        <img src="assets/imgClose.png" @click="removePayImg(index)" />
                    </div>
                    <img :src="imgFile"  @click="showImg(imgFile,payImageViews)" />
                </div>
                <input type="file" accept="image/jpeg,image/png" multiple="multiple" ref="payFileUpload" style="display: none;"/>
                <img v-if="payImageViews.length<3" src="assets/fileupload.png" @click="choosePayImage"/>
            </div>
            <div class="ordershangmeng_card_tools">
                <div class="button" @click="payConfirm">确认</div>
            </div>
        </div>

        <!--        待完成-->
<!--        <div class="ordershangmeng_card title bottom" v-else-if="state=='待完成'">-->
<!--            <div class="ordershangmeng_card_info">提交完成信息</div>-->
<!--            <div class="ordershangmeng_card_imgs">-->
<!--                <img src="assets/fileupload.png"/>-->
<!--            </div>-->
<!--            <div class="ordershangmeng_card_tools">-->
<!--                <div class="button">确认</div>-->
<!--            </div>-->
<!--        </div>-->

        <!--        详情-->
        <div class="ordershangmeng_card title bottom" v-else-if="state=='详情'">
            <div class="ordershangmeng_card_info">结果说明</div>
            <div class="detail_result">
                恭喜，中签啦
            </div>
            <div class="detail_remark" v-html="detailRemark">

            </div>
        </div>

        <!--        定标人信息-->
        <div class="ordershangmeng_card title bottom" v-if="item.campaignVo!=null && item.campaignVo!=undefined">
            <div class="labelinfo">定标人信息</div>
            <div class="order_card_headerImg">
                <img :src="item.campaignVo.avatar"/>
                <div class="peoplesinfo">
                    <div class="p0 p1">{{ item.campaignVo.name }}</div>
                    <div class="p0 p2">{{ item.campaignVo.idCard }}</div>
                    <div class="p0 p3">{{ item.campaignVo.profession }}</div>
                </div>
            </div>
        </div>

        <!--        支付信息-->
        <div class="ordershangmeng_card title bottom" v-if="item.payPictures!=null && item.payPictures.length>0">
            <div class="ordershangmeng_card_info">支付信息</div>
            <div class="ordershangmeng_card_imgs margin_bottom_30">
                <img v-for="(imgFile,index) in item.payPictures" :key="'signinfo'+index" :src="imgFile"  @click="showImg(imgFile,item.payPictures)" />
            </div>
        </div>

        <!--        签到信息-->
        <div class="ordershangmeng_card title bottom" v-if="item.signPicList!=null && item.signPicList.length>0">
            <div class="ordershangmeng_card_info">签到信息</div>
            <div class="ordershangmeng_card_imgs margin_bottom_30">
                <img v-for="(imgFile,index) in item.signPicList" :key="'signinfo'+index" :src="imgFile"  @click="showImg(imgFile,item.signPicList)" />
            </div>
        </div>

        <!--        完成信息-->
        <div class="ordershangmeng_card title bottom" v-if="item.completePicList!=null && item.completePicList.length>0">
            <div class="ordershangmeng_card_info">完成信息</div>
            <div class="ordershangmeng_card_imgs margin_bottom_30">
                <img v-for="(imgFile,index) in item.completePicList" :key="'completedinfo'+index" :src="imgFile" @click="showImg(imgFile,item.completePicList)"  />
            </div>
        </div>

        <!--        提出异议-->
        <div class="ordershangmeng_card title bottom" v-if="state=='异议'">
            <div class="ordershangmeng_card_info">提交异议现场</div>
            <div class="ordershangmeng_card_imgs">
                <div style="position:relative" v-for="(imgFile,index) in unAgreeImageViews" :key="'unagree'+index" >
                    <div class="close">
                        <img src="assets/imgClose.png" @click="removeUnAgreeImg(index)" />
                    </div>
                    <img :src="imgFile"  @click="showImg(imgFile,unAgreeImageViews)" />
                </div>
                <input type="file" accept="image/jpeg,image/png" multiple="multiple" ref="unAgreeFileUpload" style="display: none;"/>
                <img v-if="unAgreeImageViews.length<3" src="assets/fileupload.png" @click="chooseUnAgreeImage"/>
            </div>
            <div class="ordershangmeng_card_imgs card_introd">
                <textarea placeholder="其他描述" v-model="unAgreeText" />
            </div>
            <div class="ordershangmeng_card_tools">
                <div class="button" @click="unAgreeConfirm">确认</div>
            </div>
        </div>

    </div>
</template>

<script>
    import {Toast} from 'mint-ui';
    import keys from "../../xyg/utils/keys";
    export default {
        name: "OrderAction",
        data(){
            return {
                unAgreeImages:[],
                unAgreeImageViews:[],
                payImages:[],
                payImageViews:[],
                detailRemark:'1.请携带好您的 <br> 2. <br> 3.',
                // state:'',
                info:'1.要求本科以上学历 <br> 2.<br> 3.<br> 4.<br> 5.<br> 6.<br>',
                unAgreeText:''
            }
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                console.log(this.state)
                // console.log(this.$route.params.state);
                // this.state=this.$route.params.state;
                if(this.state=='去支付') {
                    document.title = "待上传支付凭证";
                }else if(this.state=='待完成'){
                    document.title = "待完成服务";
                }else if(this.state=='详情'){
                    document.title = "订单详情";
                }

                let that=this;
                if(this.$refs.payFileUpload!=null &&
                 this.$refs.payFileUpload!=undefined){
                    this.$refs.payFileUpload.addEventListener('change',function (event) {
                        that.payImageChange(event.target);
                    })
                }

                if(this.$refs.unAgreeFileUpload!=null && this.$refs.unAgreeFileUpload !=undefined){
                    this.$refs.unAgreeFileUpload.addEventListener('change',function (event) {
                        that.unAgreeImageChange(event.target);
                    })
                }
            },
            payImageChange(target){
                let files=target.files;
                if(files==null) return;
                if(files==undefined) return;
                if(files.length==0) return;
                if(files.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                if(this.payImages.length>3){
                    Toast({
                        message: '最多只能选择3张图片,不能再添加图片了',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                let that=this;
                for(var i=0 ;i<files.length;i++){
                    this.payImages.push(files[i]);
                    var reader=new FileReader();
                    reader.readAsDataURL(files[i]);
                    reader.onload=function (e) {
                        that.payImageViews.push(e.target.result);
                    }
                }

                target=null;
            },
            choosePayImage(){
                this.$refs.payFileUpload.click();
            },
            payConfirm(){ //确认上传
                if (this.payImages.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                if(this.payImages.length<=0){
                    Toast({
                        message: '最少选择一张支付凭证图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                let openId=localStorage.getItem(keys.openId);
                var formData=new FormData();

                for(var i=0 ;i<this.payImages.length;i++){
                    formData.append('files[]',this.payImages[i]);
                }
                formData.append('pId',this.item.id);
                formData.append('openId',openId);

                this.$upload("/api/product/uploadPay",formData)
                    .then(rsp=>{
                        if (rsp.code==200 && rsp.success){
                            Toast({
                                message: '提交成功',
                                position: 'bottom',
                                duration: 1500
                            });
                            this.reload(this.callback_loadConfirm);
                        }else{
                            Toast({
                                message: rsp.message,
                                position: 'bottom',
                                duration: 1500
                            });
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            showImg(path,imgs){
                // let pics=[];
                // this.item.pictures.forEach(t=>{
                //     pics.push(t);
                // });
                if(imgs.length>0) {
                    // console.log('pictures',pics)
                    this.$wx.previewImage({
                        current: path,
                        urls: imgs
                    });
                }
            },
            unAgreeImageChange(target){
                let files=target.files;
                if(files==null) return;
                if(files==undefined) return;
                if(files.length==0) return;
                if(files.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                if(this.payImages.length>3){
                    Toast({
                        message: '最多只能选择3张图片,不能再添加图片了',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                let that=this;
                for(var i=0 ;i<files.length;i++){
                    this.unAgreeImages.push(files[i]);
                    var reader=new FileReader();
                    reader.readAsDataURL(files[i]);
                    reader.onload=function (e) {
                        that.unAgreeImageViews.push(e.target.result);
                    }
                }
            },
            chooseUnAgreeImage(){
                this.$refs.unAgreeFileUpload.click();
            },
            unAgreeConfirm(){
                if (this.unAgreeImages.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                if (this.unAgreeImages.length<=0){
                    Toast({
                        message: '最少选择一张异议的图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                if(this.unAgreeText==''){
                    Toast({
                        message: '请输入异议信息',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                let openId=localStorage.getItem(keys.openId);
                var formData=new FormData();
                for(var i=0 ;i<this.unAgreeImages.length;i++){
                    formData.append('files[]',this.unAgreeImages[i]);
                }
                formData.append('pId',this.item.id);
                formData.append('openId',openId);
                formData.append('detail',this.unAgreeText);

                this.$upload("/api/product/objections",formData)
                    .then(rsp=>{
                        if (rsp.code==200 && rsp.success){
                            Toast({
                                message: '提交成功',
                                position: 'bottom',
                                duration: 1500
                            });
                            this.reload(this.callback_loadFinishing);
                        }
                        else{
                            Toast({
                                message: rsp.message,
                                position: 'bottom',
                                duration: 1500
                            });
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            removePayImg(idx){
                // this.payImages.remove(idx);
                this.payImages.splice(idx,1);
                this.payImageViews.splice(idx,1);
            },
            removeUnAgreeImg(idx){
                this.unAgreeImages.splice(idx,1);
                this.unAgreeImageViews.splice(idx,1);
            }
        }
    }
</script>

<style scoped>
    .ordershangmeng{
        width: 100vw;
    }
    .ordershangmeng .ordershangmeng_card{
        background-color: white;
        border-radius: 10px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header{
        margin-left: 30px;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header .p1{
        font-size: 26px;
        font-weight: bold;
        margin-top: 16px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header .p2{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 16px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header img{
        width: 24px;
        height: 24px;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header .p2 .location{
        font-size: 22px;
        color: #A79F9F;
        margin-left: 10px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content{
        border-top: 2px #E4E4E4 dotted;
        font-size: 26px;
        display: flex;
        flex-direction: column;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item{
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 16px;
        letter-spacing: 2px;
        display: flex;
        flex-direction: row;
        line-height: 1;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item .p1{
        color: #A79F9F;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item .p2{
        flex: 1;
        margin-left: 16px;
        letter-spacing: unset !important;
        line-height: 1;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item .p3{

    }

    .item .p4{
        margin-top: 16px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item img{
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }

    .remark{
        flex-direction: column !important;
        margin-bottom: 16px;
    }

    .title{
        display: flex;
        flex-direction: column !important;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_info{
        font-size: 28px;
        margin-left: 30px;
        margin-top: 20px;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_imgs{
        margin-left: 30px;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
    }
    .margin_bottom_30{
        margin-bottom: 30px;
    }

    .ordershangmeng_card_imgs .close{
        position: absolute;
        width: 40px;
        height: 40px;
        right: 0;
        top: 0;
        text-align: center;
        line-height: 40px;
        margin-top: unset !important;
    }

    .ordershangmeng_card_imgs .close img{
        width: 40px !important;
        height: 40px !important;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_imgs img{
        width: 150px;
        height: 150px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_tools{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_tools .button{
        margin-right: 30px;
        color: white;
        background-color: #226DF8;
        box-shadow: 0px 2px 4px rgba(117,164,245,0.5);
        border-radius: 8px;
        padding-left: 68px;
        padding-right: 68px;
        padding-top: 23px;
        padding-bottom: 23px;
        margin-bottom: 30px;
    }
    .bottom{
        margin-bottom: 30px;
    }

    .bottom .detail_result{
        color: #226DF8;
        font-size: 26px;
        margin-left: 30px;
        margin-top: 30px;
        letter-spacing: 2px;
    }

    .bottom .detail_remark{
        margin-left: 30px;
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 26px;
    }

    .ordershangmeng_card .labelinfo{
        font-size: 26px;
        letter-spacing: 2px;
        /*font-weight: bold;*/
        margin-top: 16px;
        margin-left: 30px;
    }

     .order_card_headerImg{
        display: flex;
        flex-direction: row;
        margin-top: 30px;
         margin-left: 30px;
    }
    .order_card_headerImg img{
        width: 60px;
        height: 60px;
        border-radius: 60px;
        margin-right: 0 !important;
    }

    .order_card_headerImg .peoplesinfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
    }
    .order_card_headerImg .peoplesinfo .p0{
        margin-bottom: 16px;
        color:#A79F9F;
        letter-spacing: 4px;
    }

    .p1{
        font-size: 26px;
    }
    .p2{
        font-size: 26px;
    }

    .p3{
        font-size: 26px;
    }

    .card_introd{
        margin-right: 30px;
        font-size: 26px;
        margin-bottom: 30px;
        display: flex;
    }

    .card_introd textarea{
        flex: 1;
        height: 200px;
    }

</style>
